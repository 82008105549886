import Api from '@/api'
import store from '@/store'
import router from '@/router'
import { API_BASEURL } from '@/store/constants'

const FINANCE = '/reports/finance/'
const FINANCE_EXPORT = '/reports/export/finance/'
const RECRUITERS = '/reports/recruiters/'
const DEPARTMENTS = '/reports/departments/'
const STATUS = '/reports/status/'
const REASONS = '/reports/reasons/'
const NOTE_POST = '/reports/note/'
const NOTE = '/reports/note/?rp_id=:rp_id'
const TOTAL_REVENUE = '/reports/revenue/total/'
const SYNC_RF = 'sync/rf/'
const PIVOTTABLE = 'reports/pivot-table/'
const EXCELPIVOT = 'pivot-table/export/'
const CHART_REPORT = 'reports/chart/'
const COLUMNS = 'reports/columns/finance/'
const COMMISSIONRECRUITER = 'reports/commission-recruiter/'
const COMMISSIONSALE = 'reports/commission-sales/'
const COMMISSIONOTHER = 'reports/commission-other/'
const AI_RP = 'ai/recruitment-process/'

export default {
  putCommissionRecruiter (data) {
    return Api.http(true).put(COMMISSIONRECRUITER + data.id + '/', data)
  },
  deleteCommissionRecruiter (id) {
    return Api.http(true).delete(COMMISSIONRECRUITER + id + '/')
  },
  postCommissionRecruiter (data) {
    return Api.http(true).post(COMMISSIONRECRUITER, data)
  },
  getCommissionRecruiter (id) {
    return Api.http(true).get(COMMISSIONRECRUITER + '?report_id=' + id)
  },
  putCommissionSale (data) {
    return Api.http(true).put(COMMISSIONSALE + data.id + '/', data)
  },
  deleteCommissionSale (id) {
    return Api.http(true).delete(COMMISSIONSALE + id + '/')
  },
  postCommissionSale (data) {
    return Api.http(true).post(COMMISSIONSALE, data)
  },
  getCommissionSale (id) {
    return Api.http(true).get(COMMISSIONSALE + '?report_id=' + id)
  },
  putCommissionOther (data) {
    return Api.http(true).put(COMMISSIONOTHER + data.id + '/', data)
  },
  deleteCommissionOther (id) {
    return Api.http(true).delete(COMMISSIONOTHER + id + '/')
  },
  postCommissionOther (data) {
    return Api.http(true).post(COMMISSIONOTHER, data)
  },
  getCommissionOther (id) {
    return Api.http(true).get(COMMISSIONOTHER + '?report_id=' + id)
  },
  // getFinance (page, ordering, query, search, download=false) {
  //   if (download) {
  //     return Api.http(true).get(FINANCE_EXPORT + '?' + (ordering !== '' ? '&ordering=' + ordering : '') + (query !== '' ? query : '') + (search !== '' ? '&search=' + search : ''), {
  //       responseType: 'blob'
  //     })
  //   }
  //   return Api.http(true).get(FINANCE + '?page=' + page + (ordering !== '' ? '&ordering=' + ordering : '') + (query !== '' ? query : '') + (search !== '' ? '&search=' + search : ''))
  // },
  getFinance(page, ordering, query, search, download = false, hideRhinos = false) {
    let queryParams = `?page=${page}${ordering !== '' ? '&ordering=' + ordering : ''}${query !== '' ? query : ''}${search !== '' ? '&search=' + search : ''}${hideRhinos ? '&hide_rhinos=true' : ''}`;

    if (download) {
      return Api.http(true).get(FINANCE_EXPORT + queryParams, {
        responseType: 'blob'
      });
    }

    return Api.http(true).get(FINANCE + queryParams);
},
  // deleteFinance (id) {
  //   return Api.http(true).delete(FINANCE + id + '/')
  // },
  getRecruiters () {
    return Api.http(true).get(RECRUITERS)
  },
  getManagers () {
    return Api.http(true).get(RECRUITERS)
  },
  getDepartments () {
    return Api.http(true).get(DEPARTMENTS)
  },
  getStatus () {
    return Api.http(true).get(STATUS)
  },
  getReasons () {
    return Api.http(true).get(REASONS)
  },
  patchFinanceReport (data) {
    return Api.http(true).patch(FINANCE + data.id + '/', data)
  },
  putFinanceReport (data) {
    return Api.http(true).put(FINANCE + data.id + '/', data)
  },
  getAFinanceReport (id) {
    return Api.http(true).get(FINANCE + id + '/')
  },
  getNote (id) {
    return Api.http(true).get(NOTE.replace(':rp_id', id))
  },
  setNote (data) {
    return Api.http(true).post(NOTE_POST, data)
  },
  deleteNote (id) {
    return Api.http(true).delete(NOTE_POST + id + '/')
  },
  totalRevenue (page, ordering, query, search, hideRhinos) {
    return Api.http(true).get(TOTAL_REVENUE + '?page=' + page + (ordering !== '' ? '&ordering=' + ordering : '') + (query !== '' ? query : '') + (search !== '' ? '&search=' + search : '' + (hideRhinos ? '&hide_rhinos=true' : '')))
  },
  syncRf () {
    return Api.http(true).post(SYNC_RF, {})
  },
  getPivotTable (data) {
    return Api.http(true).post(PIVOTTABLE, data)
  },
  excelPivot (query, mode='excel') {
    if (mode === 'pdf') {
      return Api.http(true).post(EXCELPIVOT + '?export=pdf', query, {
        responseType: 'blob'
      })
    }
    return Api.http(true).post(EXCELPIVOT, query, {
      responseType: 'blob'
    })
  },
  getChart (data) {
    return Api.http(true).post(CHART_REPORT, data)
  },
  postFinance (data) {
    return Api.http(true).post(FINANCE, data)
  },
  getFinanceColumns () {
    return Api.http(true).get(COLUMNS)
  },
  postFinanceColumns (data) {
    return Api.http(true).post(COLUMNS, data)
  },
  putFinanceColumns (data) {
    return Api.http(true).put(COLUMNS + data.id + '/', data)
  },
  getAIRp (data) {
    return Api.http(true).post(AI_RP, data)
  }
}